<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employees</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Employees</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            
            <b-row>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-4 col-md-2 col-sm-12 col-form-label text-nowrap"
                            style="font-weight:bold; font-size:1rem !important;"
                        >Group</label>
                        <div class="col-lg-8 col-md-10 col-sm-12">
                            <b-form-select
                                value-field="item"
                                text-field="name"
                                v-model="urlBody.employee_group_id"
                                :options="employee_groups"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                            style="font-weight:bold; font-size:1rem !important;"
                        >Status</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                value-field="item"
                                text-field="name"
                                v-model="urlBody.employee_status"
                                :options="employee_status"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>

                <b-col lg="1" class="mb-1rem">
                    <button class="btn btn-primary" @click="onSelection()">
                        Filter
                    </button>
                </b-col>
                <b-col lg="2" class="mb-1rem text-nowrap" v-if="hasPermission('CAN_CREATE_EMPLOYEE')">
                    <router-link
                        :to="{name: 'create-employee'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Employee
                    </router-link>
                </b-col>
                <b-col lg="3" sm="12" class="mb-1rem text-right">
                    <h3>Total Employees: {{totalEmployees}}</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <b-form-group
                        label="Employee"
                        label-cols-sm="2"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-weight:bold; font-size:1rem !important;"
                    >
                        <b-input-group size="md" >
                            <div class="col-md-10">
                                <EmployeeList @emp_id="getEmpId" ref="employeeId"></EmployeeList>
                            </div>
                            <b-button
                                :disabled="!filter"
                                @click="onFiltered"
                                class="btn btn-primary col-md-2"
                                variant="primary"
                            >Search</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col lg="6" class="mb-1rem">
                    <b-form-group
                        label="Reporting to"
                        label-cols-sm="2"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0 text-nowrap"
                        style="font-weight:bold; font-size:1rem !important;"
                    >
                        <b-input-group size="md" >
                            <div class="col-md-10">
                                <EmployeeList @emp_id="getReportingToId" ref="reportingToId"></EmployeeList>
                            </div>
                            <b-button
                                :disabled="!reporting_to_id"
                                @click="onFilteredReportingTo"
                                class="btn btn-primary col-md-2"
                                variant="primary"
                            >Search</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
          
            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                @row-clicked="rowCilck"
                style="cursor:pointer; overflow-y:hidden;"
                responsive
            >
                <template v-slot:cell(actions)="row">
                    <span class="actions text-nowrap">
                        <router-link
                            v-if="hasPermission('CAN_UPDATE_EMPLOYEE')"
                            :to="({ name: 'edit-employee', params: { id:  row.item.id}})"
                            v-b-tooltip.hover title="Edit"
                            class="circle"
                        >
                            <font-awesome-icon
                                icon="pen"
                            ></font-awesome-icon>
                        </router-link>

                        <router-link
                            :to="({ name: 'employee-attendance', params: {full_name:  row.item.full_name, employee_id:  row.item.id}})"
                            v-b-tooltip.hover title="Attendance"
                            class="circle ml-1"
                        >
                            <font-awesome-icon icon="calendar-alt" class="text-success"></font-awesome-icon>
                        </router-link>
                        <router-link
                            :to="{ name: 'payroll', params: {emp_id:  row.item.id}}"
                            v-b-tooltip.hover title="Payroll"
                            class="circle ml-1"
                            v-if="hasPermission('CAN_MANAGE_PAYROLL')"
                        >
                            <font-awesome-icon icon="rupee-sign" class="text-info"></font-awesome-icon>
                        </router-link>
                        <router-link
                            :to="({ name: 'employee-history', params: {id:  row.item.id}})"
                            v-b-tooltip.hover title="History"
                            class="circle ml-1"
                        >
                            <font-awesome-icon icon="history" style="color:black"></font-awesome-icon>
                        </router-link>
                    </span>
                </template>

                <template v-slot:cell(id)="row">
                    {{row.item.full_name}} [IES-{{row.item.id}}]
                </template>
                <template v-slot:cell(status)="row">
                       <b-badge :variant="setBadgeColor(row.item.status)" > {{ row.item.status }} </b-badge>
                </template>
                <template v-slot:cell(payroll_status)="row">
                       <b-badge :variant="setBadgeColor(row.item.payroll_status)" > {{ row.item.payroll_status }} </b-badge>
                </template>
                <template v-slot:cell(reporting_to)="row">
                      {{ row.item.reporting_to.full_name }} [IES-{{ row.item.reporting_to.id }}]
                </template>

            </b-table>

            <b-row align-h="between" v-if="totalRows">
                <b-col lg="4" class="p-3">
                    <div class="row input-group">
                        <div class="input-group-append my-2">Showing</div>
                        <div class="mx-1em">
                            <select
                                class="form-control"
                                v-model="urlBody.per_page"
                                @change="changePageCount"
                            >
                                <option
                                    v-for="option in pageOptions"
                                    :key="option.index"
                                >{{option}}</option>
                            </select>
                        </div>
                        <div class="input-group-append my-2" v-if="totalRows>urlBody.per_page">of {{totalRows}}</div>
                    </div>
                </b-col>
                <b-col lg="4" class="p-3">
                    <nav aria-label="Page navigation" class="justify-content-center">
                        <ul class="pagination float-right">
                            <li class="page-item">
                                <span
                                    class="page-link"
                                    v-on:click="pagination(urlBody.page - 1)"
                                    v-if="urlBody.page > 1"
                                    style="cursor:pointer"
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-else
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                            </li>
                            <li class="page-item">
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-if="urlBody.page == lastPage"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                                <span
                                    class="page-link"
                                    v-else
                                    v-on:click="pagination(urlBody.page +1)"
                                    style="cursor:pointer"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                            </li>
                        </ul>
                    </nav>
                </b-col>
            </b-row>
        </b-container>
        <!-- History modal -->
        <b-modal 
            :id="history.id" 
            centered  
            hide-footer
            size="xl"
            return-focus="history data"
        >
            <template #modal-title>
                History
            </template>
            <b-table
                show-empty
                striped
                hover
                :items="history.rows"
                :fields="history.fields"
                no-local-sorting
                responsive
                v-if="historyTable"
                @row-clicked="historyRowCilck"
                style="cursor:pointer;"
            >
             <template v-slot:cell(status)="row">
                       <b-badge :variant="setBadgeColor(row.item.status)" > {{ row.item.status }} </b-badge>
                </template>
                <template v-slot:cell(payroll_status)="row">
                       <b-badge :variant="setBadgeColor(row.item.payroll_status)" > {{ row.item.payroll_status }} </b-badge>
                </template>
                <template v-slot:cell(full_name)="row">
                    {{row.item.full_name}}[IES-{{row.item.id}}]
                </template>
                <template v-slot:cell(reporting_to)="row">
                   {{ row.item.reporting_to_name }} [IES-{{ row.item.reporting_to }}]
                </template>
                <template v-slot:cell(show_details)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowingG ? 'Hide' : 'Show'}} Details
                    </b-button>
                </template>
                <template v-slot:row-details="row">
                    <b-card>
                        <h4 class="text-sm-center">Employee Details</h4>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Employee Name:</b></b-col><b-col>{{ row.item.full_name }} [IES-{{ row.item.id }}]</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Date of Joining:</b></b-col> <b-col>{{ dateformat(row.item.doj) }}</b-col>
                       </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Gender:</b></b-col><b-col>{{ row.item.gender}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Designation:</b></b-col><b-col>{{ row.item.designation }}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Employee Group Name:</b></b-col><b-col>{{ row.item.employee_group_name }}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Holiday Group Name:</b></b-col><b-col>{{ row.item.holiday_group_name }}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Leave Group Name:</b></b-col><b-col>{{ row.item.leave_group_name }}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Week Offs:</b></b-col> <b-col>{{ hashTags(row.item.week_off)}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Reporting To:</b></b-col><b-col>{{ row.item.reporting_to_name }} [IES-{{ row.item.reporting_to }}]</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Employee Status:</b></b-col> <b-col><b-badge :variant="setBadgeColor(row.item.status)" > {{ row.item.status }} </b-badge></b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Payroll Status:</b></b-col> <b-col> <b-badge :variant="setBadgeColor(row.item.payroll_status)" > {{ row.item.payroll_status }} </b-badge> </b-col>
                        </b-row>
                        <br>
                        <h4 class="text-sm-center">Personal Details</h4>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Date of Birth:</b></b-col><b-col>{{ row.item.dob}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Gender:</b></b-col><b-col>{{ row.item.gender}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Father Name:</b></b-col><b-col>{{ row.item.father_name}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Mother Name:</b></b-col><b-col>{{ row.item.mother_name}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Marital Status:</b></b-col><b-col>{{ row.item.marital_status}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Mobile Number:</b></b-col><b-col>{{ row.item.mobile_number}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Phone Number:</b></b-col><b-col>{{ row.item.phone_number}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Personal Email:</b></b-col><b-col>{{ row.item.personal_email}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Current Address:</b></b-col><b-col>{{ row.item.current_address}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Permanent Address:</b></b-col><b-col>{{ row.item.permanent_address}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Aadhar Number:</b></b-col><b-col>{{ row.item.adhar_number}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>PAN Number:</b></b-col><b-col>{{ row.item.passport_number}}</b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3" class="text-sm-left"><b>UAN Number:</b></b-col><b-col>{{ row.item.uan}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>ESIC Number:</b></b-col><b-col>{{ row.item.esic}}</b-col>
                        </b-row>
                         <br>
                        <h4 class="text-sm-center">Bank Details</h4>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>Bank Name:</b></b-col><b-col>{{ row.item.bank_name}}</b-col>
                            <b-col sm="3" class="text-sm-left"><b>Bank Account Number:</b></b-col><b-col>{{ row.item.bank_account_number}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-left"><b>IFSC Code:</b></b-col><b-col>{{ row.item.ifsc_code}}</b-col>
                        </b-row>
                   </b-card>
                </template>
            </b-table>
        </b-modal>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
export default {
    components: {
        NotFound,
        EmployeeList
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
                page: 1,
                per_page: 10,
                employee_status: 'ACTIVE',
                employee_group_id:'',
            },
            api_error: "",
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "id", sortable: true, label:'Employee',tdClass: 'text-nowrap'},
                { key: "reporting_to", label:"Reporting To",tdClass: 'text-nowrap'},
                { key: "employee_group.name", label:"Employee Group"},
                { key: "designation", label:"Designation"},
                { key: "mobile_number", label:"Mobile No"},
                { key: "doj", label: "Date of Joining", sortable: true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { key: "status", sortable: true},
                { key: "payroll_status", sortable: true},
                { key: "actions", label: "Actions" },
            ],
            badge_colors:[
                {
                    status: "ACTIVE",
                    color:'success'
                },
                {
                    status: "TERMINATED",
                    color:'danger'
                },
                {
                    status: "ABSCONDED",
                    color:'warning'
                },
                {
                    status: "RESIGNED",
                    color:'secondary'
                },
                {
                    status: "HOLD",
                    color:'danger'
                }
            ],
            items: null,
            totalRows: 1,
            totalEmployees: 0,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            historyTable:true,
            historyView:false,
            employee_details: [],
            history: {
                id: 'history-modal',
                title: 'History',
                fields: [
                    { key: "full_name", sortable: true, label:'Emp',tdClass: 'text-nowrap'},
                    { key: "reporting_to", label:"Reporting To",tdClass: 'text-nowrap'},
                    { key: "employee_group_name", label:"Emp Group"},
                    { key: "designation", label:"Designation"},
                    { key: "doj", label: "DOJ", sortable: true,
                        formatter: value => {
                            return this.dateformat(value);
                        }
                    },
                    { key: "status", sortable: true},
                    { key: "payroll_status"},
                    { label: "Updated by",key: "updated_by_name", sortable: false },
                    { key: "updated_at", sortable: false },
             
                ],
                rows: []
            },
            employee_groups:[
                { item: '', name: "All" },
            ],
            employee_status:[
                { item: 'ACTIVE', name: "ACTIVE" },
                { item: 'RESIGNED', name: "RESIGNED" },
                { item: 'ABSCONDED', name: "ABSCONDED" },
                { item: 'TERMINATED', name: "TERMINATED" },
            ],
            reporting_to_id:null
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_EMPLOYEES");
        if (this.$route.query && this.$route.query.query) {
            this.filter = this.$route.query.query;
        }
        this.getEmployees(this.getDefaultFilters());
        this.getEmployeeGroups();
        this.getEmpId();
        this.getReportingToId();
        if (this.$route.query.employee_status) {
            this.urlBody.employee_status = this.$route.query.employee_status;
        }
        if (this.$route.query.employee_group_id) {
            this.urlBody.employee_group_id = this.$route.query.employee_group_id;
        }
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        
        getDefaultFilters() {
            let api_params = Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.deleteEmptyKeys(this.urlBody);  
            return api_params;
        },

        getEmpId(id) {
            this.filter = id;
        },

        getReportingToId(id) {
            this.reporting_to_id = id;
        },

        onSelection() {
            let api_params = this.deleteEmptyKeys(this.urlBody);
            this.getEmployees(api_params);
            this.$refs.employeeId.reset();
            this.$refs.reportingToId.reset();
        },

        getEmployeeGroups() {
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_employee_groups, query).then(response => {
                let result = response.data.data;
                result.forEach(employee_group => {
                    this.employee_groups.push({
                        item: employee_group.id,
                        name: employee_group.name
                    });
                });
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },

        hashTags(value){
            let hash_tags = "";
            value.forEach(tag => {
                                    hash_tags += tag.trim() + ', ';
                                });
            return hash_tags;
        },

        onFiltered() {
            this.$refs.reportingToId.reset();
            if (this.filter) {
                this.getEmployees({query:this.filter});
            }
        },

        onFilteredReportingTo() {
            this.$refs.employeeId.reset();
            if (this.reporting_to_id) {
                this.getEmployees({reporting_to_id:this.reporting_to_id});
            }
        },

        getEmployees: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_employees, query).then(response => {
                const result = response.data.data;
                this.items = result.data;
                this.urlBody.page = result.current_page;
                this.lastPage = result.last_page;
                this.urlBody.per_page = result.per_page;
                this.totalRows = result.total;
                this.totalEmployees = result.total;
                if (params.query) {
                    this.$refs.employeeId.getSelectedEmployee(params.query);
                }
                if (params.reporting_to_id) {
                    this.$refs.reportingToId.getSelectedEmployee(params.reporting_to_id);
                }
                this.api_error = "";
                this.updateProgressBar(true);
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        openHistory(item) {
            this.history.rows = JSON.parse(item.history);
            this.$root.$emit("bv::show::modal", this.history.id);
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getEmployees(urlBody);
        },

        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.api_error = "";
            this.getEmployees(newQuery);
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.api_error = "";
            this.getEmployees(newQuery);
        },

        clearSearch (event) {
            if (event.target.value === "") {
                if (!this.filter) {
                    this.api_error = "";
                    this.getEmployees(this.getDefaultFilters());
                }
            }
        },

        rowCilck(item) {
            this.$router.push({name: 'employee-details', params:{id:item.id}});
        },

        historyRowCilck(item) {
            if ( item._showDetails) {
                this.$set(item, '_showDetails', false);
            } else{
                this.$set(item, '_showDetails', true);
            }
        },

        setBadgeColor(value) {
            let data = this.badge_colors.find(status => status.status == value);
            return data.color;
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
#history-modal .modal-xl{
    max-width: 90% !important;
}
</style>
